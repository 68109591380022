import "@/style/index.css";
import Lottie from "lottie-react";
import * as dog from "@/assets/dog.json";
import * as tick from "@/assets/tick.json";
import { TypographyLead } from "./components/typography";
import { Button } from "./components/ui/button";
import { useToast } from "./hooks/use-toast";

function App() {
  const { toast } = useToast();
  const handleClick = async () => {
    await navigator.clipboard.writeText("tomaszstevenssoftware@gmail.com");
    toast({
      title: "Email address copied to clipboard!",
      action: (
        <Lottie
          animationData={tick}
          autoplay={true}
          loop={false}
          className="max-w-10"
        />
      ),
    });
  };
  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center space-y-4 overflow-y-hidden px-6">
      <Lottie
        animationData={dog}
        loop={true}
        autoplay={true}
        className="max-w-sm"
      />
      <TypographyLead className="max-w-2xl text-center">
        Are you an Edinburgh based charity looking for low-cost or free
        technical help and expertise?
      </TypographyLead>
      <Button variant={"red"} size={"lg"} onClick={handleClick}>
        Get in touch
      </Button>
    </div>
  );
}

export default App;
